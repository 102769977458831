import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import authorImg1 from '../../images/bg/author-1.jpg';
import authorImg2 from '../../images/bg/author-2.jpg';
import authorImg5 from '../../images/bg/author-5.jpg';
import authorImg6 from '../../images/bg/author-7.jpg';

import kidImg1 from '../../../static/about/kids/6.jpg';
import kidImg2 from '../../../static/about/kids/7.jpg';
import kidImg3 from '../../../static/about/kids/9.jpg';
import kidImg4 from '../../../static/about/kids/10.jpg';
import kidImg5 from '../../../static/about/kids/12.jpg';
import kidImg6 from '../../../static/about/kids/15.jpg';
import kidImg7 from '../../../static/about/kids/16.jpg';


const imgStyle = {
  maxWidth: 600
}

const Team = (props) => {
  const teams = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-teams">
      <SEO title="About" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className='col-lg-4 col-3'></div>
            <div className="col-lg-8 col-9">
              <h1>About Us</h1>
              <p>Meet the creative team that brought I Can Spell My Colors to life!</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container pt-4 pb-8'>
        <div className='row'>
          <div className="col-12 col-md-12 mb-1">
            <div className="team card-two">
              <div className="card-header-author">
                <div className="card-header-left">
                  <div className="row">
                  <div className='col-2'></div>
                  <div className='col-8'>
                    <Carousel
                      showStatus={false}
                      showThumbs={false}
                      showIndicators={true}
                      showArrows={true}
                      autoPlay={true}
                      centerMode
                      infiniteLoop
                      interval={4000}
                      >
                    <div>
                      <img style={imgStyle} src={authorImg6} data-pin-nopin="true" alt='Author Barbra Ausere Wood sits with 2 young kids outside'  />
                    </div>
                    <div>
                      <img style={imgStyle} src={authorImg1} data-pin-nopin="true" alt='Author Barbra Ausere Wood sits at a computer and works on the songs for the eBook I Can Spell My Colors.' />
                    </div>
                    <div>
                      <img style={imgStyle} src={authorImg2} data-pin-nopin="true" alt='' />
                    </div>
                    <div>
                      <img style={imgStyle} src={authorImg5} data-pin-nopin="true" alt='' />
                    </div>

                    </Carousel>
                  </div>
                  <div className='col-2'></div>

                    {/* <img
                      alt='Author Profile'
                      className="img-fluid mb-2"
                      src={authorImg}
                    /> */}
                  </div>
                </div>
                
                <div className="card-right">
                  <h2 className="card-title">Barbara Auseré Wood</h2>
                  <ul className="card-meta">
                      <li>
                        <strong>Author</strong>
                      </li>
                    </ul>
                </div>
             
             
              </div>
              <div className="team-content-author">
                Barbara Auseré Wood is the mother of five children and eleven grandchildren 
                and has been a preschool teacher for many years. 
                Her greatest joys in life are her family and love of little children. 
                Barbara holds a composite bachelor’s degree in Home Economics Education and 
                Child Development from Brigham Young University and currently resides in Utah 
                with her husband, Doug Wood.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {teams.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
              <div className="team card-two">
                <div className="card-header">
                  <div className="card-header-left">
                    {edge.node.frontmatter.image && (
                      <div className="card-image">
                        <img
                          alt={edge.node.frontmatter.title}
                          className="img-fluid mb-2"
                          src={edge.node.frontmatter.image}
                          data-pin-nopin="true"
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-right">
                    <h2 className="card-title">{edge.node.frontmatter.title}</h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{edge.node.frontmatter.jobtitle}</strong>
                      </li>
                      <li>
                        <a target="_blank" href={edge.node.frontmatter.linkedinurl}>
                          {edge.node.frontmatter.linkedinurl}
                        </a>
                      </li>
                      <li>
                        <a href={edge.node.frontmatter.email}>{edge.node.frontmatter.email}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="team-content"
                  dangerouslySetInnerHTML={{ __html: edge.node.html }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='container pb-6'>
        <h2 className='kid-title'>Meet the talented kids who sing the color songs!</h2>
        <div className='kid'>
          <div className='kid-picture' style={{ transform: 'rotate(2deg)', backgroundColor : 'red' }} >
            <img className='kid-picture-img' src={kidImg1} data-pin-nopin="true"/>
          </div>
          <div className='kid-picture' style={{ transform: 'rotate(-3deg)', backgroundColor : 'green' }}>
            <img className='kid-picture-img' src={kidImg3} data-pin-nopin="true" alt='Young children wear headphones and record the songs for the children’s eBook I Can Spell My Colors.' />
          </div>
          <div className='kid-picture' style={{ transform: 'rotate(4deg)', backgroundColor : 'blue' }}>
            <img className='kid-picture-img' src={kidImg2} data-pin-nopin="true"/>
          </div>
          <div className='kid-picture' style={{ transform: 'rotate(-2deg)', backgroundColor : 'orange' }}>
            <img className='kid-picture-img' src={kidImg4} data-pin-nopin="true"/>
          </div>
          <div className='kid-picture' style={{ transform: 'rotate(3deg)', backgroundColor : 'purple' }}>
            <img className='kid-picture-img' src={kidImg5} data-pin-nopin="true"/>
          </div>
          <div className='kid-picture' style={{ transform: 'rotate(-3deg)', backgroundColor : 'teal' }}>
            <img className='kid-picture-img' src={kidImg6} data-pin-nopin="true"/>
          </div>
          <div className='kid-picture' style={{ transform: 'rotate(4deg)', backgroundColor : 'yellow' }}>
            <img className='kid-picture-img' src={kidImg7} data-pin-nopin="true"/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            jobtitle
          }
        }
      }
    }
  }
`;

export default Team;
